import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function WindCriesMary() {
  return (
    <Layout>
      <Helmet title="Wind Cries Mary | Jimi Hendrix" />

      <h1>Wind Cries Mary</h1>

      <h2>Jimi Hendrix</h2>

      <h4>Key: Cm</h4>

      <h4>Tempo: 108</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>After all the jacks are in their boxes</p>
          <p>And the clowns have all gone to bed</p>
          <p>You can hear happiness staggering on down the street</p>
          <p>Footprints dressed in red</p>
          <p>And the wind whispers, "Mary"</p>
        </Verse>
        <Verse>
          <p>A broom is drearily sweeping</p>
          <p>Up the broken pieces of yesterday's life</p>
          <p>Somewhere, a queen is weeping</p>
          <p>Somewhere, a king has no wife</p>
          <p>And the wind, it cries, "Mary"</p>
        </Verse>
        <Verse>
          <p>The traffic lights, they turn blue tomorrow</p>
          <p>And shine their emptiness down on my bed</p>
          <p>The tiny island sags downstream</p>
          <p>'Cause the life that lived is dead</p>
          <p>And the wind screams, "Mary"</p>
        </Verse>
        <Verse>
          <p>Will the wind ever remember</p>
          <p>The names it has blown in the past?</p>
          <p>And with this crutch, its old age and its wisdom</p>
          <p>It whispers, "No, this will be the last"</p>
          <p>And the wind cries, "Mary"</p>
        </Verse>
      </Columns>
    </Layout>
  );
}
